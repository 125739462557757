import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { Nav as NavB } from "react-bootstrap";

export const Nav = styled.nav`
  background: ${({scrollnav}) => (scrollnav ? 'rgba(0,0,0,0.90)' : 'transparent')};
  height: 80px;
  margin-top: -80px;
  display: flex;
 
  
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  font-family: 'Encode Sans Expanded', sans-serif;

  @media screen and (max-width: 960px) {
      transition:0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
display: flex;

justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;

`;

export const NavLogo = styled(LinkR)`

justify-self: flex-start;
cursor: pointer;
display: flex;
align-items: center;
`;

export const MobileIcon = styled.div`
display: none;
 @media screen and (max-width: 815px) {
     display: block;
     position: absolute;
     top: 0;
     right: 0;
     transform: translate(-100%, 60%);
     font-size: 1.8rem;
     cursor: pointer;
     color: #fff;
 }
`;

export const NavMenu = styled.ul`
 display: flex;
 align-items: center;
 list-style: none;
 text-align: center;
 margin-right: -22px;

  @media screen and (max-width: 815px) {
      display: none;
      }
`;

export const NavItem = styled.li`
 height: 80px;
 
`;

export const NavLinks = styled(LinkS)`
  color: ${({scrollnav}) => (scrollnav ? '#fff' : '#000')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: -0.0375rem;
  font-weight: 500;
  
  &:hover {
    text-decoration: none;
    color: ${({scrollnav}) => (scrollnav ? '#f9ab00' : '#fff')};
  }
  &.active {
      border-bottom: 3px solid #f9ab00;
  }
`;


export const LangItem = styled(NavB.Link)`
font-weight: 700;
padding: .25rem .5rem;
font-size: .7rem;
 text-transform: uppercase;
 color: ${({scrollnav}) => (scrollnav ? '#fff' : '#000')};
 &:hover {
   color: ${({scrollnav}) => (scrollnav ? '#000' : '#fff')};
   background-color: ${({scrollnav}) => (scrollnav ? '#f9ab00' : 'transparent')} !important;
 }
 &.active {
   background-color: ${({scrollnav}) => (scrollnav ? 'rgb(52, 58, 64)' : 'rgba(0,0,0,0.20)')} !important;
 }
`;

export const LangMenu = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(2, 28px);
text-align: center;
`;