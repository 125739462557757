import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { LangMenu, LangItem, Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from "./NavbarElements";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Logo from "../../images/SCLogo.png";
import { Nav as NavB } from "react-bootstrap";



const Navbar = ({ toggle }) => {
  
    const{t} =useTranslation();
      const changeLanguage = (ln) =>{
          return()=>{
         
          i18n.changeLanguage(ln);
          };
          };
 const [scrollnav, setScrollNav] = useState(false);

 const changeNav = () => {
     if(window.scrollY >= 80) {
         setScrollNav(true)
     } else {
         setScrollNav(false)
     }
 };

 useEffect(() => {
     window.addEventListener('scroll', changeNav)
 }, []);

const toggleHome = () => {
    scroll.scrollToTop();
};

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
        
        <Nav scrollnav={scrollnav}>
            <NavbarContainer>
                <NavLogo to="/" onClick={toggleHome}><img width="100" height="70" className="d-inline-block align-center" src={Logo} alt="logo"/> </NavLogo>
                <NavB 
         variant="pills"
         activeKey={i18n.language}
         className="ml-2 mt-3 nav-pills table"
         >
             
      <LangMenu>   
    <LangItem
    className="mb-1 nav-link"
    scrollnav={scrollnav} 
    onClick={changeLanguage("de")} 
    eventKey="de"
    value="de"
    >De</LangItem>
   
   <LangItem
    
    className="mb-1 nav-link"
    scrollnav={scrollnav} 
    onClick={changeLanguage("en")} 
    eventKey="en"
    value="en"
    >En
    
    </LangItem>
    </LangMenu>  
   
</NavB>
                <MobileIcon onClick={toggle}>
                    <FaBars className="mb-3" />
                </MobileIcon>
                <NavMenu className="ml-auto">
                <NavItem >
                        <NavLinks 
                        onClick={toggleHome}
                        scrollnav={scrollnav}
                        className="mt-2"
                        to="/"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        >{t("home")}</NavLinks>
                    </NavItem>
                    <NavItem >
                        <NavLinks 
                         scrollnav={scrollnav}
                         className="mt-2"
                         to="about"
                         smooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80}
                        >{t("nav_about")}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                         className="mt-2"
                         scrollnav={scrollnav}
                         to="services"
                         smooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80}>{t("nav_services")}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                         className="mt-2"
                         scrollnav={scrollnav}
                         to="projects"
                         smooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80}>{t("nav_projects")}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                         className="mt-2"
                         scrollnav={scrollnav}
                         to="contact"
                         smooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80}>{t("nav_contact")}</NavLinks>
                    </NavItem>

                </NavMenu>

            
            </NavbarContainer>
        </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar;
