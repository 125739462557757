import styled from "styled-components";
import { Link } from "react-scroll";

export const MainInfo = styled.div`
  display: flex;
   position: absolute;
   top:0;
   left:0;
   bottom:0;
   right:0;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 1;
`;

export const MainInfoHeader = styled.h1`
   color: var(--primary-white);
   text-transform: uppercase;
   font-size: 4rem;

   @media screen and (max-width: 576px) {
    font-size: 2.0rem !important;
}
`;

export const MainOffer = styled(Link)`
 border: 0.0625rem solid var(--primary-white);
   text-transform: uppercase;
   border-radius: 1.25rem;
   padding: 0.625rem 0.75rem 0.75.rem 0.75rem;
   color: var(--primary-white);
   margin: 2rem;
   padding:0.5rem;

   &:hover {
   text-decoration: none;
   background-color: var(--primary-carolina);
   border: 0.0625rem solid var(--primary-carolina);
   color: var(--primary-white);
   transition: .2s ease-in-out;  
   }
`;