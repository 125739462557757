import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

export const SidebarContainer = styled.aside`
position: fixed;
z-index: 9999;
width: 100%;
height: 100%;
background: #0d0d0d;
display: grid;
align-items: center;
top: 0;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`
export const CloseIcon = styled(FaTimes)`
color: #fff;
&:hover {
    color: #f9ab00;
}
`;

export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`;

export const SidebarWrapper = styled.div`
color: #fff;
z-index: 23;
`;

export const SidebarMenu = styled.ul`
padding-left: 0;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(6, 100px);
text-align: center;

@media screen and (min-height: 850px) {
    grid-template-rows: repeat(4, 150px);
}
@media screen and (max-width: 480px) {
grid-template-rows: repeat(4, 80px);
}
@media screen and (max-height: 415px) {
    display: flex;
   justify-content: space-around;
}
`;

export const SidebarLink = styled(LinkS)`
display: none;
align-items: center;
justify-content: center;
font-family: 'Encode Sans Expanded', sans-serif;
text-transform: uppercase;
font-size: 1.5rem;
text-decoration: none !important;
list-style: none;
transition: 0.2s ease-in-out;
color: #fff;
cursor: pointer;
font-weight: 700;

@media screen and (max-width: 815px) {
    display: flex;
}
@media screen and (max-height: 400px) {
    font-size: 1.2rem;
}
@media screen and (min-height: 850px) {
    font-size: 2.5rem;
}
&:hover {
    color: #f9ab00;
    transition: 0.2s ease-in-out;
}
`;

export const SideBtnWrap = styled.div`
 display: flex;
 justify-content: center;
`;


export const NavLogo = styled(LinkR)`
cursor: pointer;
display: none;

@media screen and (max-width: 815px) {
    display: flex;
}

`;
export const SocialIcons = styled.div`
display: none;
justify-content: space-between;
align-items: center;
width: 240px;


@media screen and (max-width: 815px) {
    display: flex;
}

`;

export const SocialLink = styled.a`
color: #fff;
font-size: 34px;
`;

export const SocialWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1100px;
margin: 40px auto 0 auto;

@media screen and (max-width: 815px) {
    flex-direction: column;
}
`;
