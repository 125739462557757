import React, { useState, Suspense } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Particles from "react-particles-js";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header/Header";
import Speed from "./components/Speed";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import About from "./components/About";
import { Modal, Button } from "react-bootstrap";
import { css } from "@emotion/core";
import PulseSpinner from "react-spinners/PulseLoader";
import Spinner from "react-spinners/RingLoader";

const override = css`
position: absolute;
margin-left: -50px;
margin-top: -50px;
left: 50%;
border-color: grey;
`;

const Contact = React.lazy(() => import("./components/Contact/Contact"));
const Footer = React.lazy(() => import("./components/Footer"));
const Services = React.lazy(() => import("./components/Services"));
const Policy = React.lazy(() => import("./components/Policy"));
const Feedback = React.lazy(() => import("./components/Feedback/Feedback"));

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen)
  };
  const mystyle = {
    zIndex: "1051",
    background: "rgba(0,0,0,0.90)"
  };
  const{t} =useTranslation();
  const [showModal1, setShowModal1] = React.useState(false);
  return (

    <>
    <Router>
    
    <Particles 
    className="particles-canvas"
    params={{
      particles: {
        number:{
          value: 30,
          desity: {
            enable: true,
            value_area: 900
          }
        },
        shape: {
          type: "circle",
          stroke: {
            width: 6,
            color: "#f9ab00"
          }
        }
      }
    }}
    />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle} />
    <Header />
    <About />
   
    <Suspense fallback={<PulseSpinner css={override} size={15} />}>
    <Services />
    </Suspense>
    <Suspense fallback={<PulseSpinner css={override} size={15} />}>
    <Feedback />
    </Suspense>
    <Suspense fallback={<PulseSpinner css={override} size={15} />}>
    <Contact />
    </Suspense>
    <Suspense fallback={<PulseSpinner css={override} size={15} />}>
    <Footer /> 
    </Suspense>
    
    
    
    <CookieConsent 
    style={mystyle}
    buttonText={t("button_text")}
    buttonStyle={{ borderRadius: "15px", background: "transparent", border: "1px solid #fff", color: "white" }}
    >
        <Modal
          show={showModal1}
          onHide={() => setShowModal1(false)}
          
        >
         <Suspense fallback={<Spinner css={override} size={100} />}>
        <Policy />
      </Suspense>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal1(false)}>
              {t("close-btn")}
            </Button>
            
          </Modal.Footer>
        </Modal>
        <p className="underline">{t("cookies")}
          <a href="/#" className="ml-1" onClick={() => setShowModal1(true)}>
          Cookies
            </a>{t("cookies2")}</p>
        </CookieConsent>
    <Speed />   
   
    </Router>
    
   
    </>
  );
}

export default App;
