import React from "react";
import Creator from "../images/profile.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faDribbble } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


const About = () => {
    const TooltipTwitter = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Twitter
        </Tooltip>
      );
      const TooltipFacebook = (props) => (
        <Tooltip id="button-tooltip2" {...props}>
          Facebook
        </Tooltip>
      );
      const TooltipInsta = (props) => (
        <Tooltip id="button-tooltip3" {...props}>
          Instagram
        </Tooltip>
      );
      const TooltipDribbble = (props) => (
        <Tooltip id="button-tooltip4" {...props}>
          Dribble
        </Tooltip>
      );
    const{t} =useTranslation();
    return (
        <div id="about" className="container py-5">
            <div className="row">

                <div className="col-lg-6 col-xm-12">
                    <div className="photo-wrap">
                  
                    <LazyLoadImage
                      className="profile-img"
                      alt="aboutpic"
                      effect="blur"
                      height="150px"
                      src={ Creator }
                      width="150px"
                      
                      />

                      
                    
                    </div>
                    <ul className="social mt-4">
                    
							<li ><OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 300 }}
                                overlay={TooltipTwitter}
                                ><a target="_blank" rel="noopener noreferrer" href="http://www.twitter.com/lslcl3"><FontAwesomeIcon className=" icon" icon={faTwitter} size="2x"></FontAwesomeIcon></a></OverlayTrigger></li>
                            <li ><OverlayTrigger
                                
                                placement="bottom"
                                delay={{ show: 250, hide: 300 }}
                                overlay={TooltipFacebook}
                                ><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/llsllcll"><FontAwesomeIcon className=" ml-3 icon" icon={faFacebookF} size="2x"></FontAwesomeIcon></a></OverlayTrigger></li>
                            <li ><OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 300 }}
                                overlay={TooltipInsta}
                                ><a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/llsllcll"><FontAwesomeIcon className=" ml-3 icon" icon={faInstagram} size="2x"></FontAwesomeIcon></a></OverlayTrigger></li>
                            <li ><OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 300 }}
                                overlay={TooltipDribbble}
                                ><a target="_blank" rel="noopener noreferrer" href="http://www.dribbble.com/lslcl"><FontAwesomeIcon className="ml-3 icon" icon={faDribbble} size="2x"></FontAwesomeIcon></a></OverlayTrigger></li>
                            
						</ul>
                </div>
                <div className="col-lg-6 col-xm-12">
                    <h1 className="about-heading">#{t("about_title")}</h1>
                    <p>{t("about_p")}</p>
                </div>
            </div>
            
        </div>
    )
}

export default About;
