import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { css } from "@emotion/core";
import Spinner from "react-spinners/RingLoader";
import App from './App';

const override = css`
position: absolute;
margin-left: -50px;
margin-top: -50px;
top: 50%;
left: 50%;
`;

ReactDOM.render(
  <Suspense fallback={<Spinner css={override} size={100} />} >
    <App />
    </Suspense>
  , document.getElementById('root')
);


