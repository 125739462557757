import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import TwitterIcon from "@material-ui/icons/Twitter";
import CloseIcon from "@material-ui/icons/Close";
import FacebookIcon from "@material-ui/icons/Facebook";
import RedditIcon from "@material-ui/icons/Reddit";
import ShareIcon from "@material-ui/icons/Share";
import WaIcon from "@material-ui/icons/WhatsApp";
import LinkedInIcon from "@material-ui/icons/LinkedIn";



const useStyles = makeStyles((theme) => ({
  
    speedDial: {
      position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: 'transparent',
      
      }, 
  }));



export default function SpeedDials() {

const classes = useStyles();
const [open, setOpen] = React.useState(false);


const SocialLinks = {
    Facebook: "https://www.facebook.com/sharer/sharer.php?u=https://www.lslcl.de",
    Twitter: "https://twitter.com/intent/tweet?text=Checkout%20Web%20and%20IT%20Solutions:https://www.lslcl.de",
    Reddit: "https://www.reddit.com/r/test/submit?url=https://www.lslcl.de&title=Checkout%20Web%20and%20IT%20Solutions&selftext=true",
    LinkedIn: "https://www.linkedin.com/shareArticle?mini=true&url=https://www.lslcl.de",
    WhatsApp: "https://wa.me/?text=Checkout%20Web%20and%20IT%20Solutions:https://www.lslcl.de",
};

const actions = [
  { icon: (<TwitterIcon onClick={() => handleSocial('Twitter')}/>), name: 'Twitter',props: '#55acee'},
  { icon: (<FacebookIcon onClick={() => handleSocial('Facebook')}/>), name: 'Facebook',props:'#3b5999' },
  { icon: (<RedditIcon onClick={() => handleSocial('Reddit')}/>), name: 'Reddit',props:'#ff5700' },
  { icon: (<LinkedInIcon onClick={() => handleSocial('LinkedIn')}/>), name: 'LinkedIn',props: '#0077B5' },
  { icon: (<WaIcon onClick={() => handleSocial('WhatsApp')}/>), name: 'Whatsapp',props: '#25D366' },
 
];
const handleSocial = (network) => {
    handleClose();
    window.open(SocialLinks[network], '_blank');
};
  

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    if (navigator.share) {
      navigator
      .share({
        title: 'SC Web & IT Solutions',
        url: 'https://www.lslcl.de'
      }).then(() =>{
        console.log('thanks for sharing!');
      })
      .catch(console.error);
    } else{
      setOpen(true);
    }
    
};


 const [scrollspeed, setScrollSpeed] = useState(false);

 const changeSpeed = () => {
     if(window.scrollY >= 2130) {
         setScrollSpeed(true)
     } else {
         setScrollSpeed(false)
     }
 };

 useEffect(() => {
     window.addEventListener('scroll', changeSpeed)
 }, []);

  

  return (
    
    <div>
      
        <SpeedDial
          
          ariaLabel="SpeedDial"
          className={classes.speedDial}
          
          icon={<SpeedDialIcon className={classes.SpeedIcon} icon={<ShareIcon color={scrollspeed ? "#fff": "action"} />}  openIcon={<CloseIcon color="action" />} />}
          onClose={handleClose}
          onOpen={handleOpen}
          onMouseLeave={open}
          
          open={open}
          direction="up"
          FabProps={{ style: {background: scrollspeed ? "rgb(0,128,0,.5)": "transparent"} }}
        >
          {actions.map((action) => (
            <SpeedDialAction
            className="action"
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleClose}
              FabProps={{style:{"background":action.props}}}
              
            />
          ))}
        </SpeedDial>
       
        
        </div>
        
      
  );
}