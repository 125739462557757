import React from "react";
import Typed from "react-typed";
import {Link} from "react-scroll";
import { useTranslation } from "react-i18next";
import { MainInfo, MainInfoHeader, MainOffer } from "./header.css";

const Header = () => {
    const{t} =useTranslation();

    return (
        <div id="home" className="header-wraper">
            <MainInfo>
                <MainInfoHeader>{t("title")}</MainInfoHeader>
                <Typed
                className="typed"
                strings={[t("w1"),t("w2"),t("w3"),t("w4"),t("w5"),t("w6"),t("w7")]}
                typeSpeed={40}
                backSpeed={60}
                loop
                /> 
                <MainOffer spy={true} activeClass="active" isDynamic={true} smooth={true}  as={Link} href="#" offset={-80} to="contact">{t("contact")}</MainOffer>
            </MainInfo>

        </div>

    )
}

export default Header;
