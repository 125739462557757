import React from "react";
import { SocialWrapper, SocialIcons, SocialLink, NavLogo, SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from './SidebarElements';
import { useTranslation } from "react-i18next";
import Logo from "../../images/SCLogo.png";
import { FaFacebook, FaInstagram, FaTwitter, FaDribbble } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
    const{t} =useTranslation();
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
             <CloseIcon />
            </Icon>
            <SidebarWrapper>
            <NavLogo to="/" className="mb-5"><img width="150" height="100" className="mx-auto d-inline-block align-center" src={Logo} alt="logo" /></NavLogo>
                <SidebarMenu>
                    <SidebarLink 
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80} 
                    to="about" onClick={toggle}>{t("nav_about")}</SidebarLink>

                    <SidebarLink 
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80} 
                    to="services" onClick={toggle}>{t("nav_services")}</SidebarLink>

                    <SidebarLink
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80} 
                    to="projects" 
                    onClick={toggle}>{t("nav_projects")}</SidebarLink>

                    <SidebarLink 
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-78} 
                    to="contact" onClick={toggle}>{t("nav_contact")}</SidebarLink>
                    
                </SidebarMenu>
                
                <SocialWrapper>
                <SocialIcons>
                           <SocialLink href="https://www.facebook.com/llsllcll" target="_blank" aria-label="Facebook">
                               <FaFacebook />
                           </SocialLink>
                           <SocialLink href="http://www.instagram.com/llsllcll" target="_blank" aria-label="Instagram">
                               <FaInstagram />
                           </SocialLink>
                           
                           <SocialLink href="http://www.twitter.com/lslcl3" target="_blank" aria-label="Twitter">
                               <FaTwitter />
                           </SocialLink>
                           <SocialLink href="http://www.dribbble.com/lslcl" target="_blank" aria-label="Linkedin">
                               <FaDribbble />
                           </SocialLink>
                       </SocialIcons>
                       
                       </SocialWrapper>
                      
            </SidebarWrapper>
        </SidebarContainer>
            
        
    )
}

export default Sidebar;
